import React from 'react'

import { Link, withPrefix } from 'gatsby'
import { SectionTitle } from '../general/section-title'
import { pagesPath } from '../../lib/pagesPath'
import * as Styles from './takara_p_c.module.scss'

export function Takara_p_c() {
  return (
    <>
      {/* 未だにこんな業務管理していませんか？ */}
      <section className={Styles.problemWrap}>
        <SectionTitle en="SITUATION">
          新着情報
        </SectionTitle>

        <ul className={Styles.takara_p_c}>
          <li className={Styles.takara_p_c__items}><Link to={pagesPath.takara_p_c.$url()}>
            <div className={Styles.takara_p_c__items__content}>
              <div className={Styles.takara_p_c__items__content__imgbox}><img
                src={withPrefix('/takara_p_c-logo_takara.svg')}
                alt="宝印刷"
                width={160}
              /></div>
              <div className={Styles.takara_p_c__items__content__textbox}>
                <p className={Styles.takara_p_c__items__content__textbox__time}>2024.12.11</p>
                <p className={Styles.takara_p_c__items__content__textbox__text}>宝印刷とのステータス連携を開始しました</p>
              </div>
              <img
                src={withPrefix('/takara_p_c-icon_link.svg')}
                alt="記事ページへ"
                width={36}
                className={Styles.takara_p_c__items__content__iconlick}
              />
              
            </div>
          </Link></li>
        </ul>
      </section>
    </>
  )
}
